var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-footer" },
    [
      _vm.isMriyar
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("core.purchase"))),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "content" }, [
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "about-us" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("routes.about_us")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "payment" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("core.marketplacePayment")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "delivery" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("core.marketplaceDelivery")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "warranty" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("core.marketplaceWarranty")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: { to: { name: _vm.getRouteName("brands") } },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("catalog.trademarks")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: { to: { name: _vm.getRouteName("cars") } },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("catalog.catalog")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("core.info"))),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "content" }, [
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "public-offer" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("core.offerRules")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "color-label el-link el-link--default is-underline",
                        attrs: {
                          to: {
                            name: _vm.getRouteName("docs"),
                            params: { id: "privacy" },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("core.protectionPersonalInfo")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("el-link", { attrs: { href: _vm.toForSeller } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core.forSellers")) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("core.language")) + ": "
                    ),
                    _c("LangLinks"),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("core.weInSocial"))),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "content" }, [
                _c(
                  "li",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          type: "primary",
                          target: "_blank",
                          rel: "nofollow",
                          href: "https://www.facebook.com/Mriyar.ua",
                        },
                      },
                      [
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          attrs: {
                            width: "30",
                            src: require("@/assets/img/fb.svg"),
                            alt: "facebook",
                            title: "facebook",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("core.payOnSite"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "mb-1",
                      attrs: { justify: "center", align: "middle" },
                    },
                    [
                      _c("div", { staticClass: "mr-1" }, [
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          attrs: {
                            width: "74",
                            height: "60",
                            src: require("@/assets/img/visa.svg"),
                            alt: "VISA",
                            title: "VISA",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          attrs: {
                            width: "61",
                            height: "40",
                            src: require("@/assets/img/mastercard.svg"),
                            alt: "MasterCard",
                            title: "MasterCard",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { justify: "center", align: "middle" } },
                    [
                      _c("img", {
                        directives: [
                          { name: "lazy-load", rawName: "v-lazy-load" },
                        ],
                        attrs: {
                          width: "150",
                          height: "32",
                          src: require("@/assets/img/liqpay.svg"),
                          alt: "LiqPay",
                          title: "LiqPay",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "all-reserved", attrs: { justify: "center" } },
        [
          _c("p", [
            _vm._v(
              "© " +
                _vm._s(
                  _vm.$t("core.allReserved", { year: _vm.nowYear, mp: _vm.mp })
                )
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }