<template>
  <div class="page-footer">
    <div v-if="isMriyar" class="main">
      <!--      <div class="block">-->
      <!--        <div class="label">{{ $t('core.contacts') }}</div>-->

      <!--        <div class="content">-->
      <!--          <MarketplacePhones class="mr-2 mb-1" />-->
      <!--          <hr>-->
      <!--          <MarketplaceContacts :col="maxmq('md')" use-hr />-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="block">
        <div class="label">{{ $t('core.purchase') }}</div>

        <ul class="content">
          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'about-us' }
              }"
            >
              {{ $t('routes.about_us') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'payment' }
              }"
            >
              {{ $t('core.marketplacePayment') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'delivery' }
              }"
            >
              {{ $t('core.marketplaceDelivery') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'warranty' }
              }"
            >
              {{ $t('core.marketplaceWarranty') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{ name: getRouteName('brands') }"
            >
              {{ $t('catalog.trademarks') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{ name: getRouteName('cars') }"
            >
              {{ $t('catalog.catalog') }}
            </nuxt-link>
          </li>
        </ul>
      </div>

      <div class="block">
        <div class="label">{{ $t('core.info') }}</div>

        <ul class="content">
          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'public-offer' }
              }"
            >
              {{ $t('core.offerRules') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label el-link el-link--default is-underline"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'privacy' }
              }"
            >
              {{ $t('core.protectionPersonalInfo') }}
            </nuxt-link>
          </li>

          <li>
            <el-link :href="toForSeller">
              {{ $t('core.forSellers') }}
            </el-link>
          </li>
          <li>
            {{ $t('core.language') }}: <LangLinks />
          </li>
        </ul>
      </div>

      <div class="block">
        <div class="label">{{ $t('core.weInSocial') }}</div>

        <ul class="content">
          <li>
            <el-link
              :underline="false"
              type="primary"
              target="_blank"
              rel="nofollow"
              href="https://www.facebook.com/Mriyar.ua"
            >
              <img
                v-lazy-load
                width="30"
                src="@/assets/img/fb.svg"
                alt="facebook"
                title="facebook"
              >
            </el-link>
          </li>
        </ul>
      </div>

      <div class="block">
        <div class="label">{{ $t('core.payOnSite') }}</div>

        <div class="content">
          <el-row justify="center" align="middle" class="mb-1">
            <div class="mr-1">
              <img
                v-lazy-load
                width="74"
                height="60"
                src="@/assets/img/visa.svg"
                alt="VISA"
                title="VISA"
              >
            </div>
            <div>
              <img
                v-lazy-load
                width="61"
                height="40"
                src="@/assets/img/mastercard.svg"
                alt="MasterCard"
                title="MasterCard"
              >
            </div>
          </el-row>

          <el-row justify="center" align="middle">
            <img
              v-lazy-load
              width="150"
              height="32"
              src="@/assets/img/liqpay.svg"
              alt="LiqPay"
              title="LiqPay"
            >
          </el-row>
        </div>
      </div>
    </div>

    <el-row justify="center" class="all-reserved">
      <p>&copy; {{ $t('core.allReserved', { year: nowYear, mp }) }}</p>
    </el-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import env from '../services/envConstants';
// import MarketplaceContacts from './MarketplaceContacts';
// import MarketplacePhones from './MarketplacePhones';
import LangLinks from './LangLinks';

export default {
  name: 'PageFooter',

  components: {
    LangLinks
    // MarketplaceContacts
    // MarketplacePhones
  },

  data: () => ({
    mp: env.BASE_CLIENT_PATH.replace('https://', ''),
    isMriyar: env.MARKETPLACE === 'mriyar',
    nowYear: dayjs().year() + 1
  }),

  computed: {
    toForSeller () {
      const url = 'https://forseller.mriyar.ua/';
      const lang = this.$store.getters.langCode !== 'uk'
        ? `${this.$store.getters.langCode}/`
        : '';
      return `${url}${lang}`;
    }
  }
};
</script>

<style scoped lang="scss">
.page-footer {
  border-top: 1px solid $grey-300;
  border-left: 1px solid $grey-300;
  border-right: 1px solid $grey-300;

  .main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 20px;
    border-bottom: 1px solid $grey-300;
  }

  .block {
    min-width: 150px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .label {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .content {
    padding-left: 10px;

    * {
      font-size: .8rem;
    }

    li {
      margin-bottom: 10px;
    }
  }

  .all-reserved {
    padding: 8px 20px;

    * {
      font-size: .8rem;
    }
  }

  a:hover {
    //text-decoration: underline;
    color: #66b1ff;
  }
}
</style>
